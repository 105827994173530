<template>
  <transition name="slide" duration="550">
    <x-dialog v-if="!isMobile" :persistent="true" :show="show" maxwidth="480" scrollable :transition="null">
      <x-card>
        <x-card-title class="py-0 px-2 my-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="d-flex py-2" color="error" @click="show = !show">mdi-close</v-icon>
            </template>
            <span>Close</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <div class="pr-4">
            <v-chip small color="info">Notification <span class="ml-4  py-0 rounded-circle white px-2 primary--text">
                {{announcements?.length || 0}}
              </span></v-chip>
          </div>
        </x-card-title>

        <v-divider style="background-color: gray"/>
        <v-card-text>
          <div  v-for="(a, index) in getAnnouncements" :key="index">
            <h3 class="text--black font-weight-bold font-weight-black mb-4 mt-2" style="width: 100%; border-bottom: 1px solid black">{{ a.title }}</h3>
            <div v-html="parseContent(a.content)"></div>
          </div>
        </v-card-text>
        <div class="mx-2 mx-md-0" style="word-wrap: break-word; white-space: normal">

        </div>
      </x-card>
    </x-dialog>

    <x-dialog :show="show" maxwidth="480" scrollable :transition="'dialog-bottom-transition'" v-else>
      <x-card v-if="show">
        <v-card-text >
          <div v-for="(a, index) in getAnnouncements" :key="index">
            <h3 class="text--black font-weight-bold font-weight-black mb-4" style="width: 100%; border-bottom: 1px solid black">{{ a.title }}</h3>
            <div v-html="parseContent(a.content)"></div>
          </div>
        </v-card-text>
        <x-card-action>
          <x-button outlined text color="error" class="d-flex ml-auto" @click="show = !show">Close</x-button>
        </x-card-action>
      </x-card>
    </x-dialog>
  </transition>
</template>

<script>
import XDialog from "./XDialog.vue";
import XCard from "./XCard.vue";
import XCardTitle from "./XCardTitle.vue";
import XButton from "../widgets/XButton.vue";
import XCardAction from "./XCardAction.vue";
import {convertDataToHtml} from "@/utils/EditorJSUtil";

export default {
  name: "Announcement",
  components: {XCardAction, XButton, XCardTitle, XCard, XDialog},
  props: {
    announcements: {
      type: Array
    },
    show: Boolean
  },
  data: () => ({

  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.xs;
    },

    getAnnouncements: function(){
      if (this.announcements == null || this.announcements.length === 0)
        return []

      return this.announcements.toReversed()
    }
  },

  methods: {
    parseContent(content){
      return convertDataToHtml(JSON.parse(content).blocks);
    }
  }
}
</script>

<style scoped lang="scss">
.v-dialog {
  .v-card {
    position: absolute;
    justify-items: start;
    top: 0;
    right: 0;
    height: 100vh;
    width: 480px;
    max-width: 480px;
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;


    @media screen and (max-width: 480px) and (orientation: portrait) {
      position: sticky;
      left: 0;
      bottom: 0;
      top: 5em;
      width: 480px;
      max-width: 480px;
      height: 100vh;
      min-height: 0;
      scroll-behavior: smooth;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: none;
    }
  }
}

a {
  color: rgba(0, 0, 255, 0.85) !important;
}
</style>
